import styled from "styled-components";

import { MdOutlineDelete } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";

export const ProductsMain = styled.div`
    background-color: var(--theme-mode-primary);
    overflow: hidden;
    
    @media screen and (min-width: 600px) {
        width: 500px;
        margin: 0 auto;
        border-radius: 10px;
    }

    @media screen and (min-width: 1000px) {
        padding: 15px 0 0 0;
        width: 90vw;
        margin: 0 5vw;
    }

    @media screen and (min-width: 1255px) {
        width: 70vw;
        margin: 0 15vw;
    }
`

export const ProductsOverflow = styled.div`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    @media screen and (min-width: 1000px) {
        overflow-x: hidden;
    }
`

export const ProductsTable = styled.div`
    width: 108vw;
    margin: 0 15px;
    gap: 15px;
    min-height: ${props => props.products ? 'unset' : '225px'};
    max-height: 250px;

    @media screen and (min-width: 500px) {
        width: unset;
        max-height: 45vh;
    }
`

const product = `
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 60px;
    border-radius: 10px;
`

export const ProductsHead = styled.div`
    ${product}    
    background-color: var(--color-white-3);
    font-weight: 700;
    position: relative;
`

export const ProductBoxTitle = styled.span`
    flex-grow: 1;
    overflow-x: scroll;
    margin-right: 10px;
    margin-top: 10px;

    ::-webkit-scrollbar {
        display: ${props => props.maxWidth || 'none'};
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    :-webkit-scrollbar-track {
        background-color: #f2f2f2;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 10px;
      border: 2px solid #f2f2f2;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #aaa;
    }

    @media screen and (min-width: 1000px) {
        max-width: unset;
    }
`

export const ProductPublic = styled.span`
    width: 90px;
    min-width: 90px;
    padding: 10px 0;
    text-align: center;
    background-color: ${props => props.color ? '#0f05' : '#f005'};
    border-radius: 20px;
    color: var(--color-dark-blue);
    margin-right: 10px;

    @media screen and (min-width: 1000px) {
        margin-right: 20px;
    }
`

export const ProductBoxState = styled.span`
    width: 70px;

    @media screen and (min-width: 1000px) {
        width: 90px;
        margin-right: 20px;
    }
`

export const ProductBoxActions = styled.span`
    margin: 10px;
`

export const ProductsContainer = styled.div`
    max-height: ${props => `${props.height / 2.5}px`};
    overflow: auto;
    width: calc(100% + 10px);

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    :-webkit-scrollbar-track {
        background-color: #f2f2f2;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 10px;
      border: 2px solid #f2f2f2;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #aaa;
    }

    @media screen and (min-width: 1000px) {
        max-height: ${props => `${props.height / 2.1}px`};
    }
`

export const ProductsBody = styled.div`
    ${product}    
    background-color: #fff;
    max-width: 99%;

    @media screen and (min-width: 1000px) {
        :hover {
            background-color: var(--color-white-3);
        }
    }
`

export const ProductBoxEdit = styled.div`
    margin: 10px;
    display: flex;
    gap: 20px;
    color: #999;
    
    @media screen and (min-width: 500px) {
        gap: 20px;
    }
`

export const ProductEditIcon = styled(FiEdit2)`
    @media screen and (min-width: 1000px) {
        box-sizing: unset;
        border-radius: 50%;
        cursor: pointer;
        width: 30px;
        padding: 5px 0;
        font-size: 20px;

        :hover {
            background-color: var(--color-grey);
        }
    }
`

export const ProductDeleteIcon = styled(MdOutlineDelete)`
    @media screen and (min-width: 1000px) {
        box-sizing: unset;
        border-radius: 50%;
        cursor: pointer;
        width: 30px;
        padding: 5px 0;
        font-size: 20px;

        :hover {
            background-color: #FF000033;
        }
    }
`

export const ProductsImg = styled.img`
   margin: 20px;
   max-width: 60px;
   min-width: 60px;
   object-fit: contain;
   max-height: 50px;
   overflow: hidden;
`

export const ProductsMsg = styled.h2`
    margin-top: 70px;
    text-align: center;
`

export const ProductsFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 15px 25px 15px;
    box-shadow: 0 0 10px #0002;
    gap: 10px;
    position: relative;
    z-index: 100;
`

export const ProductsLimitText = styled.span`
  font-size: 12px;
  color: var(--color-grey-3);
`

export const Select = styled.select`
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--color-grey);
    transition: background-color .2s ease;

    @media screen and (min-width: 1000px) {
        cursor: pointer;
        :hover {
            background-color: var(--color-white-3);
        }
    }
`

export const SelectLabel = styled.label`
   font-weight: 600;
`

export const SelectDiv = styled.div`
    position: relative; 
    margin: 0 20px;
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (min-width: 1000px) {
        margin: 0 40px;
    }
`

export const SelectCategory = styled.select`
    width: 100%;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    min-width: 300px;
    cursor: pointer;
    background-color: var(--color-white-3);
    color: var(--color-dark-blue);
    font-size: 16px;

    @media screen and (min-width: 500px) {
        margin-left: ${props => props.marginLeft ? '20px' : '0'};
    }
`

export const BoxButtons = styled.div`
    display: flex;
    gap: 10px;

    span {
        color: var(--color-grey-3);
        margin: 0 20px;
    }
`

export const PaginationBtn = styled.button`
    background-color: transparent;
    border: none;
    transform: ${props => props.right ? 'rotateZ(180deg)' : 'rotateZ(0deg)'};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: ${props => props.maxPage ? 'var(--color-grey)' : 'var(--color-dark-blue)'};
    border-radius: 50%;
    transition: background-color .2s ease;

    @media screen and (min-width: 1000px) {
        cursor: ${props => props.maxPage ? 'default' : 'pointer'} ;
        :hover {
            background-color: ${props => props.maxPage || 'var(--color-light-grey)'}
        }
    }
`