import { Logo, Header, LogoContainer, LogoutIcon, Logout } from "./StylesHeader";

import { useContext, memo } from "react";
import { Context } from "../Context/ContextProvider";

import { Link } from "react-router-dom";

import logo from "../../assets/logo.png";

import { swalQuestion } from "../../utils/Swal";

// import DarkMode from "../DarkMode/DarkMode";

const AdminHeader = memo(() => {
  const { setToken } = useContext(Context);

  const handleClose = () => {
    swalQuestion({
      title: "Cerrar Sesion",
      text: "Estas seguro que quieres cerrar sesion?",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        setToken(null);
      }
    });
  };

  return (
    <Header>
      <Link to={"/"}>
        <Logo src={logo} alt="Icon" />
      </Link>
      {/* <DarkMode /> */}
      <LogoContainer>
        <Logout onClick={handleClose}>Cerrar Sesión</Logout>
        <LogoutIcon onClick={handleClose} />
      </LogoContainer>
    </Header>
  );
});

export default AdminHeader;
