import logo from "../../assets/logo.png";

import { MdOutlinePhoneEnabled, MdOutlineEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMapPin, HiOutlineClock } from "react-icons/hi2";

import {
  FooterContainer,
  FooterList,
  FooterItem,
  Icon,
  CopyRightBox,
  CopyRigth,
  CopyRightLogo,
} from "./StylesFooter";

import { Br } from "../Theme/Styles";
import { memo } from "react";
import { Link } from "react-router-dom";

const Footer = memo(() => {
  const handleLogo = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <FooterContainer>
      <FooterList>
        <FooterItem order={1}>
          <Icon>
            <MdOutlinePhoneEnabled />
          </Icon>
          4280 2505
        </FooterItem>

        <FooterItem order={4}>
          <Icon>
            <HiOutlineMapPin />
          </Icon>
          <a href="https://goo.gl/maps/AZx97xy1LKkZg4vU9" rel="noreferrer" target={"_blank"}>
            Tucumán 2947, Quilmes Oeste
          </a>
        </FooterItem>
        <FooterItem order={3}>
          <Icon>
            <FaWhatsapp />
          </Icon>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a href="https://wa.me/5491155774509" rel="noreferrer" target={"_blank"}>
              TALLER: 11 5577 4509
            </a>
            <a href="https://wa.me/5491173636375" rel="noreferrer" target={"_blank"}>
              VENTAS: 11 7363 6375
            </a>
            <a href="https://wa.me/5491140887228" rel="noreferrer" target={"_blank"}>
              ADMINISTRACION: 11 4088 7228
            </a>
          </div>
        </FooterItem>
        <FooterItem order={2}>
          <Icon>
            <MdOutlineEmail />
          </Icon>
          <div>
            <Link onClick={handleLogo} to={"/contacto"}>
              TALLER: Frenosbattelini@gmail.com
            </Link>
            <br />
            VENTAS: frenosbatteliniventas@gmail.com
          </div>
        </FooterItem>
        <FooterItem order={5}>
          <Icon>
            <HiOutlineClock />
          </Icon>
          Horario de atención: Lu A Vie 8 HS A 18:30 HS
        </FooterItem>
      </FooterList>
      <CopyRightBox>
        <CopyRigth>
          © 2023 Todos los derechos reservados <Br /> Taller de frenos Battelini. Powered by Viops
        </CopyRigth>
        <CopyRightLogo src={logo} onClick={handleLogo} alt={"logo"} />
      </CopyRightBox>
    </FooterContainer>
  );
});

export default Footer;
