import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
`

export const Form = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    background-color: var(--color-white);
    border-radius: 10px;
    width: 90%;
    height: 300px;
    max-width: 500px;
`

export const InputBox = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 2px;
`

export const Label = styled.label`
    width: max-content;
    @media screen and (min-width: 1000px){
        cursor: pointer;
    }
`

export const Input = styled.input`
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: var(--color-grey)
`

export const SendBtn = styled.input`
    width: 60%;
    background-color: var(--color-dark-blue);
    color: var(--color-white);
    font-weight: 700;
    padding: 10px 0;
    border-radius: 10px;

    @media screen and (min-width: 1000px){
        cursor: pointer;
    }
`