import styled, { keyframes, css } from "styled-components";

import { BsPatchCheckFill } from 'react-icons/bs'

import mainImg from "../../assets/main.png";

export const MainImgContainer = styled.div`
    height: 350px;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    background-image: url(${mainImg});
    background-repeat: no-repeat;
    background-size: 100% 400px;
    margin-bottom: 5px;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (min-width: 1000px) {
        background-attachment: fixed;
        background-size: 100% 100vh;
        height: 100vh;
    }
`

export const MainImgLogo = styled.img`
    width: 60%;
    margin-bottom: 20px;

    @media screen and (min-width: 1000px) {
        width: 40%;
    }
`

export const MainTitle = styled.h1`
    font-weight: 100;
    font-size: 40px;
    line-height: 1;

    @media screen and (min-width: 1000px) {
        font-size: 80px;
    }
`

export const MainSubtitle = styled.p`
    color: var(--color-white-3);

    @media screen and (min-width: 1000px) {
        font-size: 26px;
    }
`

export const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1000px) {
        flex-direction: row;
        gap: 10px;
    }
`

export const Box = styled.div`
    width: 100%;
    background-color: var(--color-white);
    border-radius: 10px;
    margin: 5px 0;
    flex-grow: ${props => props.grow ? '1' : '0'};
    padding: ${props => props.icon ? '40px' : '20px'};

    @media screen and (min-width: 1000px) {
        width: ${props => props.width};
        padding: ${props => props.icon ? '70px 100px' : '70px'};
    }
`

export const BoxTitle = styled.h3`
    margin-bottom: 10px;
    font-size: 30px;
    transition: transform .2s;
    font-weight: 200;
    color: var(--color-dark-blue);
`

export const BoxIcon = styled(BsPatchCheckFill)`
    color: var(--color-blue);
    position: absolute;
    left: -20px;
    top: 5px;
    width: 15px;

    @media screen and (min-width: 1000px) {
        left: -30px;
    }
`

export const BoxText = styled.p`
    line-height: 1.8;
    transition: transform .2s;

    div {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-top: 10px;

        img {
            width: 40%;
        }
    }

    @media screen and (min-width: 1000px) {
        width: ${props => props.width}%;
    }
`

export const BoxImagesContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    position: relative;
    padding-bottom: 20px;

    @media screen and (min-width: 1000px) {
        margin-top: 20px;
        padding-bottom: 0;
    }
`

export const BoxImagesFragment = styled.div`
    height: 40px;
    width: 20%;
    position: relative;

    @media screen and (min-width: 1000px) {
        height: 80px;
    }
`

const animationImage = keyframes`
    from {
        transform: scale(.8, .8);
    }

    to {
        transform: scale(1, 1);
    }
`

export const BoxImages = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 50;
    transform: scale(.8, .8);
    background-color: var(--color-white);
    padding-right: ${props => props.paddingR && '5px'};
    padding-left: ${props => props.paddingL && '5px'};
    ${props => props.animation && css`
        animation: ${animationImage} .3s ${props => props.delay}s linear normal forwards;
    `}
`

const animatioLine = keyframes`
    from {
        width: 0%;
    }
    to {
        width: 80%;
    }
`

export const BoxLine = styled.div`
    position: absolute;
    left: 10%;
    top: 35%;
    height: 1.5px;
    width: 0;
    background-color: var(--color-blue);
    ${props => props.animation && css`
        animation:${animatioLine} 1s linear normal forwards;
    `}

    @media screen and (min-width: 1000px) {
        top: 50%;
    }
`

export const BoxYear = styled.span`
    width: 100%;
    font-size: 12px;
    position: absolute;
    bottom: -22px;
    text-align: center;
    left: 0;
`