import Layout from "../Layout/Layout";

import { Helmet } from "react-helmet-async";

import { MainTitleContainer, MainTitle, MainSubtitle } from "../Workshop/StylesWorkshop";

import Box from "./Box";
import { memo } from "react";

import VigiaImg from "../../assets/Vigia.png";

const Vigia = memo(() => {
  return (
    <Layout>
      <Helmet>
        <title>Vigia</title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Servicio oficial Colven. VIESA es un sistema de enfriamiento ecológico capaz de reducir la temperatura en el interior
          de las cabinas de camiones. El calibrador electrónico VIGÍA mantiene de forma constante y automática la presión
          predeterminada de los neumáticos de camiones, remolques, semirremolques y autobuses."
        />
        <meta
          name="keywords"
          content="Mecánica camiones, Calibración neumáticos Vigía, Sistema enfriamiento Viesa"
        />
      </Helmet>

      <MainTitleContainer img={VigiaImg}>
        <MainTitle>
          SERVICIO
          <br />
          OFICIAL <br /> COLVEN
        </MainTitle>
        <MainSubtitle>Instalación y reparación</MainSubtitle>
      </MainTitleContainer>

      <Box
        src={"../imgs/vigia.png"}
        title={"VIGIA"}
        subTitle={"Calibrador Neumático"}
        alt={"Calibrador Neumático"}
      >
        El calibrador electrónico VIGÍA mantiene de forma constante y automática la presión
        predeterminada de los neumáticos de camiones, remolques, semirremolques y autobuses. Ante
        cualquier disminución en la presión, incluso en caso de pinchazo, el sistema VIGÍA avisa al
        conductor y activa de forma automática el proceso de inflado, protegiendo así los
        neumáticos. Los beneficios de contar con este sistema no sólo se perciben en las rutas,
        también se reflejan en la rentabilidad de las operaciones, ya que permite reducir el consumo
        de combustible.
      </Box>
      <Box src={"../imgs/viesa.png"} title={"VIESA"} normal={true} alt={"VIESA"}>
        VIESA es un sistema de enfriamiento ecológico capaz de reducir la temperatura en el interior
        de las cabinas de camiones, maquinaria agrícola, autocaravanas y demás transporte pesado
        incluso en las condiciones de calor extremo en el exterior.
        <br />
        El sistema VIESA crea un clima fresco y confortable aunque la temperatura sea muy elevada
        fuera del vehículo. Actualmente, es líder en refrigeración en los más de 20 países a los que
        se exporta esta tecnología ecoeficiente. Los enfriadores VIESA funcionan con el motor
        apagado, evitando el consumo de combustible y las emisiones de CO2.
      </Box>
    </Layout>
  );
});

export default Vigia;
