import { useEffect, useState } from "react";

import "./Checkbox.css";

export default function Checkbox({ allCheck, id, setDeleteProducts, deleteProducts }) {
  const [state, setState] = useState(false);

  const handleClick = () => setState(!state);

  useEffect(() => {
    if (state && !deleteProducts.includes(id)) {
      setDeleteProducts([...deleteProducts, id]);
    } else if (!allCheck) {
      const actualProducts = [...deleteProducts];
      const position = actualProducts.indexOf(id);
      if (position !== -1) {
        actualProducts.splice(position, 1);
        setDeleteProducts([...actualProducts]);
      }
    }
  }, [state]);

  useEffect(() => setState(allCheck), [allCheck]);

  return (
    <label className="cont">
      <input className="checkbox" type="checkbox" checked={state} onChange={handleClick} />
      <span className="checkbox-span"></span>
    </label>
  );
}
