import {
  ProductsFooter,
  ProductsLimitText,
  Select,
  BoxButtons,
  PaginationBtn,
} from "./StylesProducts";

import { FiChevronLeft } from "react-icons/fi";

import { useCallback } from "react";

export default function ProductFooterTable({
  state,
  setLimit,
  setPage,
  setOffset,
  handlePagePrev,
}) {
  const handleSelect = useCallback((e) => setLimit("setLimit", Number(e.target.value)), [setLimit]);

  const handlePageNext = () => {
    if (state.page < state.maxPage) {
      setPage("setPage", state.page + 1);
      setOffset("setOffset", state.limit * state.page);
    }
  };
  return (
    <ProductsFooter>
      <ProductsLimitText>Resultados por página</ProductsLimitText>
      <Select onChange={handleSelect} defaultValue={10}>
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
      </Select>
      <BoxButtons>
        <span>
          {state.page}-{state.maxPage}
        </span>
        <PaginationBtn aria-label="button prev" onClick={handlePagePrev} maxPage={state.page === 1}>
          <FiChevronLeft />
        </PaginationBtn>
        <PaginationBtn
          aria-label="button next"
          right={true}
          onClick={handlePageNext}
          maxPage={state.page === state.maxPage}
        >
          <FiChevronLeft />
        </PaginationBtn>
      </BoxButtons>
    </ProductsFooter>
  );
}
