import { DataContainer, DataTitle, Button } from "../Workshop/StylesWorkshop";

import MiniDropdownBox from "./MiniDropdownBox";

import { useState, useRef, useLayoutEffect, useId } from "react";

import DropdownBtn from "../Workshop/DropdownBtn";

import closeDropdowns from "../../utils/closeDropdown";

export default function DropdownBoxWithBoxes({ item }) {
  const [boxHeight, setBoxHeight] = useState(0);
  const btnRef = useRef();
  const itemsRef = useRef();

  const id = useId;

  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
    setBoxHeight(itemsRef.current.offsetHeight * 1.1);
  };

  closeDropdowns(".dropdownBtn", btnRef.current, setOpen);

  useLayoutEffect(() => setBoxHeight(itemsRef.current.offsetHeight * 1.1), []);

  return (
    <DataContainer open={open} boxHeight={boxHeight}>
      <Button aria-label={"dropdown"} className="dropdownBtn" ref={btnRef} onClick={handleClick}>
        <DropdownBtn open={open} />
      </Button>
      <DataTitle>{item.title}</DataTitle>
      <div ref={itemsRef}>
        {item.info.map((subItem, i) => {
          return (
            <MiniDropdownBox
              setParentBox={setBoxHeight}
              title={subItem.title}
              description={subItem.description}
              key={id + i}
            />
          );
        })}
      </div>
    </DataContainer>
  );
}
