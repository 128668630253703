import styled, { createGlobalStyle } from "styled-components";

const themes = (a, b) => props => props.theme ? a : b

export const GlobalStyles = createGlobalStyle`
:root {
    --color-blue: #006ed1; 
    --color-dark-blue: #0f1a2a; 
    --color-dark-blue-2: #1e2a3b; 
    --color-dark-blue-3: #27364b; 
    --color-grey-blue: #475569; 
    --color-grey-3: #64748b; 
    --color-grey-2: #94a3b8; 
    --color-grey: #cbd4e1; 
    --color-light-grey: #e2e8f0; 
    --color-white-3: #f1f4f9; 
    --color-white-2: #f6f8fc; 
    --color-white: #ffffff;
    
    --theme-mode-primary: ${themes('var(--color-dark-blue)', 'var(--color-white)')};
    --theme-mode-secondary: ${themes('var(--color-dark-blue-2)', 'var(--color-white-2)')};
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
      "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-white-3);
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Bebas Neue', cursive;
  }

  p, pre{
    font-family: 'Inter', sans-serif;
    color: var(--color-dark-blue-3);
  }

  span {
    font-family: 'Inter', sans-serif;
    color: var(--color-dark-blue);
  }

  ul {
    list-style: none;
  }
  
  a {
    text-decoration: none;
  }
  
  a { -webkit-tap-highlight-color: rgba(0,0,0,0); }

  input:focus, textarea:focus {
    outline: none
  }

  select:focus {
    outline: none;
  }
`

export const Br = styled.span`
    display: block;

    @media screen and (min-width: 1000px) {
        display: inline;
    }
`