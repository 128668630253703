import styled from "styled-components"

export const AsideBox = styled.div`
    margin: 5px 0;
    background-color: var(--color-light-grey);
    padding: 40px 15% 40px 20px;
    text-align: left;
    position: relative;
    border-radius: 10px;
    transition: transform .2s;

    @media screen and (min-width: 1000px) {
        padding: 70px 20% 70px 70px;

        :hover {
            transform: rotateY(-1.5deg);
        }
        :hover > div::before,:hover > div::after {
            box-shadow: inset 30px 0 50px var(--color-light-grey);
        }
        :hover > p, :hover > h3 {
            transform: translateX(-1px);
        }

        :hover > button {
            transform: translateX(-10px);
            box-shadow: 10px 0 8px #0003;
        }

        :hover > button:active {
            transform: translate(0, 0);
            box-shadow: 0 0 0 #0003;
        }
    }
`

export const BoxButton = styled.button`
    color: var(--color-white);
    background-color: var(--color-dark-blue);
    border: none;
    margin-top: 25px;
    border-radius: 20px;
    transition: all .2s;
    cursor: pointer;

    a:visited, a:link {
        color: var(--color-white);
        display: block;
    }

    a {
        padding: 12px 30px;
        position: relative;
        bottom: 2px;
    }

    a svg{
        font-size: 20px;
        position: relative; 
        top: 5px;
        right: 5px;
    }
`

export const AsideImgBox = styled.div`
    display: none;

    @media screen and (min-width: 1000px) {
        display: inline-block;
    }

    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 45%;
    overflow: hidden;
    border-radius: 10px;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 50px 0 50px var(--color-light-grey);
        transition: all .5s;
    }
    
    ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 50px 0 50px var(--color-light-grey);
        transition: all .5s;
    }
    `

export const AsideImg = styled.img`
    height: 100%;
    width: 100%;
`