import { Container, Form, InputBox, Label, Input, SendBtn } from "./StylesLogin";

import { useState } from "react";

import { toastError, toastWarn, toastSuccess } from "../../utils/Toast.js";

export default function Login({ setToken }) {
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });

  const handleChange = ({ target }) => {
    setInputValues((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  const saveToken = async (token) => {
    localStorage.setItem("token", token);
    await setToken(token);
  };

  const submit = (e) => {
    e.preventDefault();
    fetch("https://admin.battelinifrenos.com.ar/login", {
      method: "POST",
      body: JSON.stringify({
        email: inputValues.email,
        password: inputValues.password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) toastError(data.error);
        else {
          toastSuccess("Bienvenido!");
          setTimeout(() => {
            saveToken(data.token);
          }, 1000);
        }
      })
      .catch((error) => toastWarn("Error: no se pudieron enviar los datos"))
      .finally(
        setInputValues({
          email: "",
          password: "",
        })
      );
  };
  return (
    <Container>
      <Form onSubmit={submit}>
        <InputBox>
          <Label htmlFor={"Usuario"}>Usuario</Label>
          <Input
            required
            type={"text"}
            id={"Usuario"}
            placeholder={"Usuario"}
            value={inputValues.email}
            onChange={handleChange}
            name={"email"}
          />
        </InputBox>
        <InputBox>
          <Label htmlFor={"Contraseña"}>Contraseña</Label>
          <Input
            required
            type={"password"}
            id={"Contraseña"}
            placeholder={"Contraseña"}
            value={inputValues.password}
            onChange={handleChange}
            name={"password"}
          />
        </InputBox>
        <SendBtn type={"submit"} value={"Entrar"} />
      </Form>
    </Container>
  );
}
