import { ImageContainer, Image } from "./StylesABS";
import { DataContainer, DataTitle, DataDescription, Button } from "../Workshop/StylesWorkshop";

import { useState, useRef, useLayoutEffect, useId } from "react";

import DropdownBtn from "../Workshop/DropdownBtn";

import closeDropdowns from "../../utils/closeDropdown";

export default function DropdownBox({ item }) {
  const [boxHeight, setBoxHeight] = useState(0);
  const btnRef = useRef();
  const textRef = useRef();
  const imgsRef = useRef();

  const id = useId();

  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  closeDropdowns(".dropdownBtn", btnRef.current, setOpen);

  useLayoutEffect(() => {
    setBoxHeight(textRef.current.offsetHeight);
    imgsRef.current && setBoxHeight((prev) => prev + imgsRef.current.offsetHeight + 30);
  }, []);

  return (
    <DataContainer open={open} boxHeight={boxHeight}>
      <DataTitle>{item.title}</DataTitle>
      <Button aria-label={"dropdown"} className="dropdownBtn" ref={btnRef} onClick={handleClick}>
        <DropdownBtn open={open} />
      </Button>
      <DataDescription ref={textRef}>{item.description}</DataDescription>
      {item.hasOwnProperty("images") && (
        <ImageContainer ref={imgsRef}>
          {item.images.map((image, index) => (
            <Image key={id + index} src={image} alt="nacionales e importados" />
          ))}
        </ImageContainer>
      )}
    </DataContainer>
  );
}
