import { memo } from "react";
import { StyledBox, Image, Title, Subtitle, Text } from "./StylesBox";

const Box = memo(({ src, title, subTitle, normal, children }) => {
  return (
    <StyledBox invert={normal}>
      <Image src={src} alt={"Imagen de Vigia"} />
      <div>
        <Title>{title}</Title>
        {subTitle && <Subtitle>{subTitle}</Subtitle>}
        <Text>{children}</Text>
      </div>
    </StyledBox>
  );
});

export default Box;
