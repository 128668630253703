import styled from "styled-components";

import { AiOutlineSearch } from "react-icons/ai";

import { Link } from "react-router-dom";

export const SearchBox = styled.form`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 5px;
`

export const Select = styled.select`
    width: 30%;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    min-width: 300px;
    cursor: pointer;
    background-color: var(--color-dark-blue);
    color: var(--color-light-grey);
    font-size: 16px;

    @media screen and (min-width: 500px) {
        margin-left: ${props => props.marginLeft ? '20px' : '0'};
    }
`

export const Search = styled.input`
    width: 100%;
    padding: 20px 50px 20px 20px;
    border-radius: 5px;
    margin: 5px 0;
    border: none;

    ::placeholder{
        font-weight: 600;
        font-size: 15px;
        color: var(--color-dark-blue);
    }

    @media screen and (min-width: 1000px) {
        margin: 10px 0;
    }
    `

export const SearchIcon = styled(AiOutlineSearch)`
    box-sizing: unset;
    position: absolute;
    top:0;
    bottom: 0;
    right: 5px;
    margin: auto 0;
    width: 20px;
    padding: 10px;
    border-radius: 50%;

    @media screen and (min-width: 1000px) {
        cursor: pointer;
    }
`

export const ProductsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
    place-items: center;
    width: 100%;
    gap: 5px;
    min-height: 350px;
    position: relative;
    
    @media screen and (min-width: 1000px) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        margin-bottom: 10px;
        gap: 20px;
    }
`

export const ProductLink = styled(Link)`
    :active,:visited,:link{
        color: unset;
    }
`

export const ProductStyled = styled.div`
    width: 165px;
    height: 260px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.loader ? 'var(--color-white)' : 'var(--color-light-grey)'};
    padding: 20px;
    gap: 5px;
    transition: background .2s ease;
    overflow: hidden;

    @media screen and (min-width: 1000px) {
        width: 250px;
        height: 350px;
        cursor: pointer;
        position: relative;
        --display: none;

        ::after {
            display: none;
            content: 'Ver más';
            font-family: 'Inter', sans-serif;
            position: absolute;
            left: var(--x);
            top: var(--y);
            width: 80px;
            height: 30px;
            transform: translate(-50%,-150%);
            z-index: 200;
            background-color: var(--color-dark-blue);
            color: var(--color-light-grey);
            border-radius: 20px;
            font-size: 15px;
        }

        ::before {
            display: none;
            content: '';
            position: absolute;
            left: var(--x);
            top: var(--y);
            width: 25px;
            height: 15px;
            transform: translate(-50%,-110%) rotateZ(180deg);
            clip-path: polygon(var(--polygonX) var(--polygonY), 0% 100%, 100% 100%);
            z-index: 200;
            background-color: var(--color-dark-blue);
            color: var(--color-light-grey);
            transition: clip-path .1s;
        }

        :hover::before {
            display: var(--display);
        }
        
        :hover::after {
            display: var(--display);
            justify-content: center;
            align-items: center;
        }

        :hover {
            background-color: var(--color-white);
        }
    }
`

export const ProductTitle = styled.span`
    font-weight: 600;
    pointer-events: none;
    word-wrap: break-word;
    max-width: 100%;
`

export const ProductImg = styled.img`
    width: 100%;
    height: 190px;
    margin: 10px 0 20px;
    object-fit: contain;
    position: relative;
    pointer-events: none;

    @media screen and (max-width: 1000px){
        max-height: 50%;
    }
`

export const ProductMsg = styled.h2`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const ShowMoreBtn = styled.button`
    display: block;
    width: 165px;
    border-radius: 5px;
    border: none;
    background-color: var(--color-dark-blue);
    color: var(--color-white);
    padding: 15px 0;
    font-size: 20px;
    font-weight: 600;
    margin: 20px auto;
    font-family: 'Inter', sans-serif;

    @media screen and (min-width: 1000px) {
        width: 250px;
        cursor: pointer;
    }
`

export const PromotionPrice = styled.span`
   margin-right: 10px;
   font-size: 13px;
   position: relative;
   bottom: 1px;
   opacity: 0.5;

   :after {
    content: '';
    width: 150%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: -25%;
    background: #000;
    opacity: 0.5;
   }
`