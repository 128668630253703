import "./ToggleButton.css";

export default function ToggleButton({ showPrice, handlePrice }) {
  return (
    <label className="toggleSwitch">
      <input type="checkbox" checked={showPrice} onChange={handlePrice} />
      <span className="slider"></span>
    </label>
  );
}
