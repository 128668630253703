import ContentLoader from "react-content-loader";

import { useContext } from "react";
import { Context } from "../Context/ContextProvider";

const MyLoader = (props) => {
  const { showPrice } = useContext(Context);

  return (
    <ContentLoader
      speed={2}
      width={200}
      height={300}
      viewBox="0 0 200 300"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="43" y="150" rx="0" ry="0" width="1" height="4" />
      <rect x="0" y="15" rx="0" ry="0" width={props.width} height="190" />
      <rect x="0" y="230" rx="0" ry="0" width={props.width} height="12" />
      {showPrice && <rect x="0" y="260" rx="0" ry="0" width="100" height="12" />}
    </ContentLoader>
  );
};

export default MyLoader;
