import styled from "styled-components";

export const StyledBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-white);
    margin: 10px 0;
    border-radius: 10px;
    padding: 15px;

    @media screen and (min-width: 1000px) {
        padding: 30px;
        flex-direction: row-reverse;
        flex-direction: ${props => props.invert && 'row'};
    }
`

export const Image = styled.img`
    height: 200px;
    margin: 0 20px;
    
    @media screen and (min-width: 1000px) {
        height: 350px;
    }
`

export const Title = styled.h3`
    text-align: left;
    width: 100%;
    font-weight: 100;
    font-size: 25px;
`

export const Subtitle = styled.h4`
    font-family: Inter;
    text-align: left;
    width: 100%;
    font-size: 15px;
    margin: 10px 0;
`

export const Text = styled.p`
    line-height: 1.5;
`