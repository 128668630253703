import styled from "styled-components";

import { AiOutlineSearch } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";

export const ProductsControllers = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;

    @media screen and (min-width: 1000px) {
        justify-content: space-between;
        flex-direction: row;
    }
`

export const DeleteBox = styled.div`
    position: absolute;
    top: 20px;
    right: 10px;
    width: 35px;
    height: 35px;
    background-color: #FF000011;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: filter .3s ease;
    filter: ${props => props.selectedProducts ? 'opacity(1)' : 'opacity(0)'};
    pointer-events: ${props => props.selectedProducts ? 'all' : 'none'};
    transition: background-color .2s ease;

    @media screen and (min-width: 1000px) {
        right: 350px;
        top: 0px;
        width: 45px;
        height: 40px;
        border-radius: 10px;
        cursor: pointer;
        :hover {
            background-color: #FF000022;
        }
    }
`

export const DeleteIcon = styled(FiTrash2)`
    width: 20px;
    height: 20px;
    color: #FF0000;
    pointer-events: none;
`

export const ImportIcon = styled(IoMdAdd)`
    width: 25px;
    height: 25px;
`

export const ImportExportContainer = styled.div`
    display: flex;
    gap: 5px;
    margin: 0 15px;
    
    @media screen and (min-width: 1000px) {
        order: 2
    }
`

const btn = `
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 40px;
    height: 40px;

    svg {
        font-size: 20px;
    }
`

const before = `
    display: none;
    background-color: var(--color-dark-blue);
    color: var(--color-light-grey);
    width: 90px;
    padding: 5px 0;
    height: auto;
    border-radius: 5px;
    position: absolute;
    left: -55%;
    bottom: -100%;
    font-size: 13px;
    text-align: center;
`

const after = `
    display: none;
    content: '';
    background-color: var(--color-dark-blue);
    width: 20px;
    height: 10px;
    padding: 5px 0;
    position: absolute;
    left: 25%;
    bottom: -35%;
    clip-path: polygon(50% 50%, 0% 100%, 100% 100%);`

export const ExportButton = styled.label`
    ${btn}
    background-color: var(--color-light-grey);
    color: var(--color-dark-blue);
    display: none;
    transition: background-color .2s ease;
    position: relative;

    @media screen and (min-width: 1000px) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        :hover {
            background-color: var(--color-grey);

            ::before {
                content: 'Exportar CSV';
                display: inline-block;
            }
            ::after {
                display: inline-block;
            }
        }

        ::before {
           ${before}
        }

        ::after {
           ${after}
        }
    }
`

export const ImportExcelBtn = styled.label`
    ${btn}
    background-color: var(--color-light-grey);
    color: var(--color-dark-blue);
    display: none;

    @media screen and (min-width: 1000px) {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        :hover {
            background-color: var(--color-grey);

            ::before {
                content: 'Importar CSV';
                display: inline-block;
            }
            ::after {
                display: inline-block;
            }
        }

        ::before {
            ${before}
        }

        ::after {
            ${after}
        }
    }
`

export const ImportButton = styled.button`
    ${btn}
    padding: 15px 0;
    background-color: var(--color-dark-blue);
    color:var(--color-light-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 200;
    transition: opacity .2s ease;
    font-weight: 600;

    @media screen and (max-width: 1000px) {
        position: fixed;
        bottom: 10px;
        left: 0;
        right: 0;
        width: 300px;
        margin: auto;
    }

    svg {
        transition: transform .2s ease;
    }

    @media screen and (min-width: 1000px) {
        flex-grow: 0;
        width: 200px;
        margin-left: 20px;
        :hover {
            opacity: 0.9;

            svg {
                transform: rotateZ(90deg);
            }
        }
    }
`

export const SearchFilterContainer = styled.div`
    display: flex;
    justify-content: left;
    margin: 0 15px;
    width: ${props => props.selectedProducts ? '70%' : '100%'};
    transition: width .3s ease;

    @media screen and (min-width: 500px) {
        justify-content: space-between;
    }
`

export const SearchBox = styled.form`
    background-color: var(--color-white-3);
    position: relative;
    padding: 12px 40px 12px 5px;
    display: flex;
    align-items: center;
    border-radius: 30px;
    width: 50%;

    @media screen and (min-width: 1000px) {
        width: 300px
    }
`

export const SearchInput = styled.input`
    border: none;
    background-color: transparent;
    height: 100%;
    margin-left: 15px;
    font-weight: 700;
    width: 100%;
`

export const SearchIcon = styled(AiOutlineSearch)`
    box-sizing: unset;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 5px;
    cursor: pointer;
`

export const FilterBox = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px;

    @media screen and (min-width: 500px) {
        justify-content: left;
        margin-left: unset;
        margin-left: 10px;
        width: 50%;
    }
`

export const FilterSelect = styled.select`
   border: none;
   position: relative;
   top: 1px;
   color: #555;
   height: 60%;
   padding: 5px;
   border-radius: 20px;
   transition: background-color .2s ease;

    @media screen and (min-width: 1000px) {
        cursor: pointer;
        :hover {
            background-color: var(--color-white-3);
        }
    }
`

export const ToggleButtonBox = styled.div`
 margin: 20px;
 display: inline-block;
`

export const BackButton = styled.button`
    width: 150px;
    padding: 10px 0;
    margin: 10px 20px;
    border-radius: 10px;
    border: none;
    background-color: var(--color-dark-blue);
    color: var(--color-light-grey);
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 15px;
    transition: opacity .2s ease;

    @media screen and (min-width: 1000px) {
        cursor: pointer;
        :hover {
            opacity: 0.9;
        }
    }
`