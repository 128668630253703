import styled from "styled-components";

import { IoCloseOutline } from 'react-icons/io5'

export const ModalBg = styled.div`
    background-color: #F4F5F7;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    overflow-y: scroll;
    z-index: 500;

    @media screen and (min-width: 600px){
       background-color: transparent;
       backdrop-filter: blur(5px)
    }
`

export const ModalContainer = styled.form`
    background-color: #fff;
    position: relative;
    width: 100%;
    padding: 30px 0 10px;

    @media screen and (min-width: 600px){
        margin: 0 auto;
        width: 60%;
        border-radius: 15px;
    }
    
    @media screen and (min-width: 1000px){
        width: 50%;
    }
`

export const ModalTitle = styled.h1`
    padding: 5px 0;
    text-align: center;
`

export const ModalClose = styled(IoCloseOutline)`
    position: absolute;
    top: 5px;
    right: 5px;
    color: #000;
    font-size: 25px;
    cursor: pointer;
`

export const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
`

const inputSpace = `
    margin: 0 5%;
    width: 90%;
`

const input = `
    background-color: var(--color-white-3);
    padding: 15px;
    border: none;
    border-radius: 10px;
`

export const ProductLabel = styled.label`
    position: absolute;
    top: -22px;
    left: ${props => props.left}px;
    font-weight: 500;
`

export const ProductInput = styled.input`
    ${inputSpace}
    ${input}
`

export const ProductBox = styled.div`
    ${inputSpace}
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
`

export const ProductFragment = styled.div`
    position: relative;
    width: ${props => props.width}%;
`

export const ProductBoxInput = styled.input`
    ${input}
    width: 100%;
`

export const ProductDescription = styled.textarea`
    ${inputSpace}
    ${input}
    height: 150px;
    resize: none;

    @media screen and (min-width: 1000px){
        height: 300px;
    }
`

export const SaveButtons = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-direction: column;
    margin: 20px 5%;

    @media screen and (min-width: 1000px){
        flex-direction: row;
        justify-content: left;
    }
`

export const CloseButton = styled.button`
    background-color: transparent;
    color: var(--color-grey-2);
    padding: 15px;
    border: none;
    border-radius: 15px;
    font-weight: 700;

    @media screen and (min-width: 1000px){
        order: 1;
        cursor: pointer;
        margin-right: 30%;
        flex-grow: 2;
    }
`

export const SaveButton = styled.button`
    background-color: ${props => props.bg === 'dark' ? 'var(--color-dark-blue)' : 'var(--color-light-grey)'} ;
    color: ${props => props.color === 'dark' ? 'var(--color-dark-blue)' : 'var(--color-light-grey)'} ;
    padding: 15px 20px;
    border: none;
    border-radius: 10px;
    font-weight: 700;

    @media screen and (min-width: 1000px){
        order: ${props => props.order};
        cursor: pointer;
    }
`