import Layout from "../Layout/Layout";

import { Helmet } from "react-helmet-async";

import { useParams, Navigate } from "react-router-dom";

import { FirstLetterUppercase } from "../../utils/FirstLetterUppercase";

import {
  Title,
  Product,
  ProductImg,
  ProductInfo,
  Description,
  ProductBox,
  WppBtn,
  WppIcon,
} from "./StylesProductDetails";
import { useEffect, useState, useContext } from "react";

import { PromotionPrice } from "./StylesShop";
import { Context } from "../Context/ContextProvider";

export default function ProductDetail() {
  const [productInfo, setProductInfo] = useState({});
  const [returnHome, setReturnHome] = useState(false);

  const { showPrice } = useContext(Context);

  const { productId } = useParams();

  useEffect(() => {
    fetch(`https://admin.battelinifrenos.com.ar/api/products/product/${productId}`)
      .then((res) => res.json())
      .then((data) => (data.data ? setProductInfo(data) : setReturnHome(true)));
  }, [productId]);

  return (
    <>
      {returnHome ? (
        <Navigate to="/" />
      ) : (
        productInfo.data && (
          <>
            <Helmet>
              <title>Ventas{` > ${productInfo.data?.name.toUpperCase()}`}</title>
              <meta charset="utf-8" />
              <meta name="description" content={`${productInfo.data?.description}`} />
              <meta
                name="keywords"
                content={`Venta de repuestos, Catalogo de repuestos, ${productInfo.data?.name}`}
              />
            </Helmet>
            <Layout>
              <Product>
                <ProductImg
                  src={`https://admin.battelinifrenos.com.ar/public/${productInfo.data?.image}`}
                  alt={`Imagen de producto: ${FirstLetterUppercase(productInfo.data?.name)}`}
                />
                <ProductInfo>
                  <Title>{FirstLetterUppercase(productInfo.data?.name)}</Title>
                  {showPrice ? (
                    productInfo.data.promotion !== "0" ? (
                      <div>
                        <PromotionPrice>$ {productInfo.data.price}</PromotionPrice>
                        <span>$ {productInfo.data.promotion}</span>
                      </div>
                    ) : (
                      <span>$ {productInfo.data.price}</span>
                    )
                  ) : (
                    <></>
                  )}
                  <Description>
                    <h3>Descripcion</h3>
                    <p>{productInfo.data?.description}</p>
                  </Description>
                  <ProductBox>
                    <span>Categoria: {productInfo.data?.category}</span>
                    <span>Etiquetas: {productInfo.data?.tags}</span>
                  </ProductBox>
                  <WppBtn
                    target={"_blank"}
                    href={`https://wa.me/5491173636375?text=Hola! Quería consultarte por: *${productInfo.data?.name}*`}
                  >
                    <WppIcon />
                    Consultar por Whatsapp
                  </WppBtn>
                </ProductInfo>
              </Product>
            </Layout>
          </>
        )
      )}
    </>
  );
}
