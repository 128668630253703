export const orderData = async (token) => {
    const res = await fetch(`https://admin.battelinifrenos.com.ar/api/products/admin?limit=0&offset=0`, {
        method: "GET",
        headers: {
            authorization: token,
        }
    })
    const products = await res.json()

    const data = await products.map((data) => data.data)
    const exportData = [...data]
    exportData.forEach((item) => {
        delete item.date;
        delete item.image
        delete item.public
    });

    const headTable = exportData.map((item) => Object.keys(item))[0];
    const bodyTable = exportData.map((item) => headTable.map((head) => item[head]));

    return { headTable, bodyTable }
}