import styled from "styled-components";

export const DataBox = styled.div`
    background-color: var(--color-light-grey);
    border-radius: 10px;
    padding: 0 15px;
    margin: 15px 0;
    height: ${props => props.open ? `calc(${props.boxHeight}px + 60px)` : '60px'};
    overflow: hidden;
    position: relative;
    transition: height .3s ease;

    @media screen and (min-width: 1000px) {
        padding: 0 30px;
        height: ${props => props.open ? `calc(${props.boxHeight}px + 40px)` : '80px'};
    }
`

export const DataBoxTitle = styled.h6`
    font-size: 22px;
    font-weight: 100;
    height: 60px;
    display: flex;
    align-items: center;
    color: var(--dark-blue);

    @media screen and (min-width: 1000px) {
        height: 80px;
    }
`

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`

export const Image = styled.img`
    height: 60px;
    max-width: 20%;
    object-fit: contain;

    @media screen and (min-width: 1000px){
        height: 125px;
    }
`

export const Btn = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    border: none;

    @media screen and (min-width: 1000px) {
        cursor: pointer;
    }
`