import {
  ProductsOverflow,
  ProductsTable,
  ProductsHead,
  ProductBoxTitle,
  ProductBoxState,
  ProductBoxActions,
  ProductsContainer,
  ProductsMsg,
} from "./StylesProducts";

import { useEffect, useContext, useLayoutEffect } from "react";

import { Context } from "../Context/ContextProvider";

import ModalEditProduct from "./Modals/ModalEditProduct";
import Loader from "./Loader/Loader";
import HeaderCheckbox from "./Checkbox/HeaderCheckbox";

import Products from "./Products";

import ProductFooterTable from "./ProductFooterTable";
import { useCallback } from "react";

export default function AdminProducts({
  setProducts,
  route,
  products,
  msgProducts,
  category,
  categories,
  state,
  dispatch,
  search,
}) {
  const { refresh, reloadProducts, setDeleteProducts, deleteProducts, token } = useContext(Context);

  const allCheckBox = () => {
    const newDeleteProducts = state.allCheck ? [] : products.map((item) => item.id);
    setDeleteProducts(newDeleteProducts);
    dispatch({ type: "setAllCheck", payload: !state.allCheck });
  };

  const modalToggle = () => dispatch({ type: "setModal", payload: !state.modal });

  const handlePagePrev = useCallback(() => {
    if (state.page > 1) {
      dispatch({ type: "setPage", payload: state.page - 1 });
      dispatch({ type: "setOffset", payload: state.limit * (state.page - 2) });
    }
  }, [state.limit, state.page, category, search]);

  useEffect(() => {
    fetch(
      `https://admin.battelinifrenos.com.ar/api/products/${
        category ? "/category/" + category.toUpperCase() : "size"
      }`,
      {
        method: "GET",
        headers: {
          authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const myData = category ? data.length : data.documents;
        if (myData > state.limit)
          dispatch({ type: "setMaxPage", payload: Math.ceil(myData / state.limit) });
        else dispatch({ type: "setMaxPage", payload: 1 });
      });
  }, [state.limit, route, refresh, token, category]);

  useEffect(() => {
    dispatch({ type: "setOffset", payload: 0 });
    dispatch({ type: "setPage", payload: 1 });
  }, [state.limit, route, category, search]);

  useEffect(() => {
    !products.length && handlePagePrev();
  }, [products, handlePagePrev]);

  useEffect(() => {
    state.loading || dispatch({ type: "setLoading", payload: true });
    const params = `?limit=${state.limit}&offset=${state.offset}&timestamp=${Date.now()}&${
      category && "category=" + category
    }`;
    fetch(`https://admin.battelinifrenos.com.ar/api/products${route}${params}`, {
      method: "GET",
      headers: {
        authorization: token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
        dispatch({ type: "setLoading", payload: false });
      })
      .catch((error) => dispatch({ type: "setLoading", payload: false }));
    dispatch({ type: "setAllCheck", payload: false });
  }, [refresh, token, route, state.limit, state.offset, setProducts, category]);

  useLayoutEffect(() => {
    dispatch({ type: "setHeight", payload: window.innerHeight });
    window.addEventListener("resize", () =>
      dispatch({ type: "setHeight", payload: window.innerHeight })
    );
    return () =>
      window.addEventListener("resize", () =>
        dispatch({ type: "setHeight", payload: window.innerHeight })
      );
  }, []);

  return (
    <>
      <ProductsOverflow>
        <ProductsTable products={products.length}>
          <ProductsHead>
            <HeaderCheckbox type="checkbox" allCheckBox={allCheckBox} allCheck={state.allCheck} />
            <ProductBoxTitle>Producto</ProductBoxTitle>
            <ProductBoxState>Estado</ProductBoxState>
            <ProductBoxActions>Acciones</ProductBoxActions>
          </ProductsHead>
          {state.loading ? (
            <Loader />
          ) : products.length ? (
            <ProductsContainer height={state.height}>
              {products.map((product) => {
                return (
                  <Products
                    key={product.id}
                    product={product}
                    modalToggle={modalToggle}
                    setModalInfo={(type, payload) => dispatch({ type: type, payload: payload })}
                    setDeleteProducts={setDeleteProducts}
                    deleteProducts={deleteProducts}
                    token={token}
                    reloadProducts={reloadProducts}
                    allCheck={state.allCheck}
                  />
                );
              })}
            </ProductsContainer>
          ) : (
            <ProductsMsg>{msgProducts}</ProductsMsg>
          )}
        </ProductsTable>
      </ProductsOverflow>
      <ProductFooterTable
        state={state}
        setLimit={(type, payload) => dispatch({ type, payload })}
        setPage={(type, payload) => dispatch({ type, payload })}
        setOffset={(type, payload) => dispatch({ type, payload })}
        handlePagePrev={handlePagePrev}
      />
      {state.modal && (
        <ModalEditProduct
          closeModal={modalToggle}
          productInfo={state.modalInfo}
          reloadProducts={reloadProducts}
          token={token}
          categories={categories}
        />
      )}
    </>
  );
}
