import { Header, LogoLink, Logo, NavList, BtnBox, NavLink, LinkTracker } from "./styles";

import logo from "../../assets/logo-white.png";

import { useState } from "react";

import MenuButton from "./MenuButton.jsx";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [trackerWidth, setTrackerWidth] = useState(0);
  const [trackerPosition, setTrackerPosition] = useState(0);
  const [showTracker, setShowTracker] = useState(false);

  const handleClick = () => setOpen(!open);

  const handleLink = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const handleLinkTracker = (e) => {
    setTrackerPosition(e.target.offsetLeft);
    setTrackerWidth(e.target.clientWidth);
    setShowTracker(true);
  };

  const handleHiddeTracker = () => setShowTracker(false);

  return (
    <Header open={open}>
      <LogoLink to={"/"}>
        <Logo src={logo} alt="logo" />
      </LogoLink>
      <nav>
        <BtnBox onClick={handleClick}>
          <MenuButton open={open} />
        </BtnBox>
        <NavList>
          <li>
            <NavLink
              onClick={handleLink}
              to={"/"}
              onMouseMove={handleLinkTracker}
              onMouseLeave={() => setShowTracker(false)}
            >
              Inicio
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={handleLink}
              to={"/taller"}
              onMouseMove={handleLinkTracker}
              onMouseLeave={handleHiddeTracker}
            >
              Taller
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={handleLink}
              to={"/ventas"}
              onMouseMove={handleLinkTracker}
              onMouseLeave={handleHiddeTracker}
            >
              Ventas
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={handleLink}
              to={"/abs-ebs"}
              onMouseMove={handleLinkTracker}
              onMouseLeave={handleHiddeTracker}
            >
              ABS-EBS
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={handleLink}
              to={"/vigia"}
              onMouseMove={handleLinkTracker}
              onMouseLeave={handleHiddeTracker}
            >
              Vigia
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={handleLink}
              to={"/contacto"}
              onMouseMove={handleLinkTracker}
              onMouseLeave={handleHiddeTracker}
            >
              Contacto
            </NavLink>
          </li>
          <LinkTracker width={trackerWidth} positionX={trackerPosition} show={showTracker} />
        </NavList>
      </nav>
    </Header>
  );
}
