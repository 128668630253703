import { verifyFields } from "./verifyFields";
import { getImageName } from "./getImageName";
import { toastSuccess, toastError, toastWarn } from './Toast'
import { deleteImage } from "./deleteImage";

export const updateProduct = async (priv, imageData, image, token, inputValues, parentProps) => {
    const { file, prevImage } = imageData
    const { productInfo, closeModal, reloadProducts } = parentProps

    if (file) {
        const resImage = await getImageName(file, token);
        image = await resImage.json();
    }

    if (prevImage && image.error) {
        return toastError("Error: Imagen no seleccionada");
    }

    if (!prevImage && !file) {
        return toastError("Error: Imagen no seleccionada");
    }

    const areValid = verifyFields(inputValues, 1);

    if (!areValid) {
        image && deleteImage(image, token);
        return toastError("Error: todos los campos tienen que estar llenos o con 1 caracter mínimo");
    }

    file && prevImage && deleteImage(prevImage, token);

    const res = await fetch(`https://admin.battelinifrenos.com.ar/api/products/existName`, {
        method: "PUT",
        body: JSON.stringify({
            productId: productInfo.id,
            name: inputValues.name,
        }),
        headers: {
            "Content-Type": "application/json",
            authorization: token,
        }
    });
    const data = await res.json();

    if (data.error) {
        return toastError(data.error);
    }

    const boolean = priv ? "public" : "priv";
    fetch(`https://admin.battelinifrenos.com.ar/api/products/updateOne?type=${boolean}`, {
        method: "PUT",
        body: JSON.stringify({
            productId: productInfo.id,
            productData: { ...inputValues, image },
        }),
        headers: {
            "Content-Type": "application/json",
            authorization: token,
        }
    })
        .then(closeModal())
        .then((res) => res.json())
        .then((data) =>
            data.error ? toastError(data.error) : toastSuccess("Producto editado exitosamente!")
        )
        .then(reloadProducts)
        .catch((error) => toastWarn("No se ha podido editar el producto"));
}