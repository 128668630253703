import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import ContextProvider from "./Components/Context/ContextProvider";
import Theme from './Components/Theme/Theme'

import Home from "./Components/Home/Home";
import Workshop from "./Components/Workshop/Workshop";
import Shop from "./Components/Shop/Shop";
import ProductDetail from "./Components/Shop/ProductDetail";
import ABS from './Components/ABS/ABS'
import Vigia from './Components/Vigia/Vigia'
import Contact from './Components/Contact/Contact'
import Admin from './Components/Admin/Admin'

import { HelmetProvider } from "react-helmet-async";

import { ToastContainer } from "react-toastify";

export default function App() {

  return (
    <HelmetProvider>
      <ContextProvider>
        <Theme />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/taller" element={<Workshop />}></Route>
            <Route path="/ventas" element={<Shop />}></Route>
            <Route path="/ventas/:productId" element={<ProductDetail />}></Route>
            <Route path="/abs-ebs" element={<ABS />}></Route>
            <Route path="/vigia" element={<Vigia />}></Route>
            <Route path="/contacto" element={<Contact />}></Route>
            <Route path="/administracion" element={<Admin />}></Route>
            <Route path="*" element={<Navigate to='/' />}></Route>
          </Routes>
        </BrowserRouter>
      </ContextProvider>
      <ToastContainer />
    </HelmetProvider>
  );
}