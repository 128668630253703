import './dropdownBtn.css'

export default function DropdownBtn({ open }) {
    return (
        <label className="container">
            <input type="checkbox" checked={open} readOnly={true} />
            <div className="line"></div>
            <div className="line line-indicator"></div>
        </label>
    )
}