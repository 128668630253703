import { DataBox, DataBoxTitle, Btn } from "./StylesABS";
import { DataDescription } from "../Workshop/StylesWorkshop";

import { useState, useRef, useLayoutEffect } from "react";

import DropdownBtn from "../Workshop/DropdownBtn";

import closeDropdowns from "../../utils/closeDropdown";

export default function MiniDropdownBox({ setParentBox, title, description }) {
  const [boxHeight, setBoxHeight] = useState(0);
  const btnRef = useRef();
  const textRef = useRef();

  const [openMini, setOpenMini] = useState(false);

  const handleClick = () => {
    setOpenMini(!openMini);
    setParentBox(undefined);
  };

  closeDropdowns(".dropdownMiniBox", btnRef.current, setOpenMini);

  useLayoutEffect(() => setBoxHeight(textRef.current.offsetHeight), [boxHeight]);

  return (
    <DataBox open={openMini} boxHeight={boxHeight}>
      <Btn className="dropdownMiniBox" ref={btnRef} onClick={handleClick}>
        <DropdownBtn open={openMini} />
      </Btn>
      <DataBoxTitle>{title}</DataBoxTitle>
      <DataDescription ref={textRef}>{description}</DataDescription>
    </DataBox>
  );
}
