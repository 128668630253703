export const getImageName = async (file, token) => {
    const formData = new FormData();
    const options = {
        method: "POST",
        body: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            authorization: token,
        }
    };
    delete options.headers["Content-Type"];

    formData.append("image", file);
    const resImage = await fetch("https://admin.battelinifrenos.com.ar/api/products/image", options);
    return resImage
}