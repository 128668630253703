import { GlobalStyles } from './Styles.js'

import { Context } from '../Context/ContextProvider'
import { useContext } from 'react'

export default function Theme() {

    const { darkMode } = useContext(Context)

    return <GlobalStyles theme={darkMode} />
}