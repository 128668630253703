import { useEffect } from "react";
import { createContext, useState } from "react";

export const Context = createContext();

export default function ContextProvider({ children }) {
  // const [darkMode, setDarkMode] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteProducts, setDeleteProducts] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [showPrice, setShowPrice] = useState(false);

  const reloadProducts = () => setRefresh(!refresh);

  // const changeTheme = () => {
  //   setDarkMode(!darkMode);
  // };

  useEffect(() => {
    fetch(`https://admin.battelinifrenos.com.ar/showPrice?timestamp=${Date.now()}`).then((res) =>
      res.json().then((data) => setShowPrice(data.data.show))
    );
  }, []);

  return (
    <Context.Provider
      value={{
        // changeTheme,
        // darkMode,
        refresh,
        reloadProducts,
        deleteProducts,
        setDeleteProducts,
        token,
        setToken,
        showPrice,
        setShowPrice,
      }}
    >
      {children}
    </Context.Provider>
  );
}
