import "./menuButton.css";

export default function MenuButton({ open }) {
  return (
    <>
      <div className="wave-left">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "20px", width: "45px" }}
        >
          <path
            d="M-44.55,-86.15 C37.27,162.97 325.65,129.09 500.60,151.01 L240.44,165.95 L-3.35,154.00 Z"
            style={{ stroke: "none", fill: "#fff" }}
          ></path>
        </svg>
      </div>
      <button aria-label="menu button" className="switch">
        <input type="checkbox" checked={open} readOnly={true} />
        <div>
          <span className="line-1"></span>
          <span className="line-2"></span>
          <span className="line-3"></span>
        </div>
      </button>
      <div className="wave-right">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "20px", width: "45px" }}
        >
          <path
            d="M-44.55,-86.15 C37.27,162.97 325.65,129.09 500.60,151.01 L240.44,165.95 L-3.35,154.00 Z"
            style={{ stroke: "none", fill: "#fff" }}
          ></path>
        </svg>
      </div>
    </>
  );
}
