import styled from "styled-components";

import { Link } from "react-router-dom";

export const Header = styled.header`
    background-color: var(--color-white);

    @media screen and (max-width: 1000px) {
        width: 100%;
        position: fixed;
        bottom: 0;
        z-index: 200;
        filter: drop-shadow(0 0 5px #0005);
        transform: ${props => props.open ? 'translateY(0)' : 'translateY(92%)'};
        transition: transform .3s ease;
    }
    
    @media screen and (min-width: 1000px) {
        margin: 10px 5%;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: var(--color-dark-blue);
    }

`

export const LogoLink = styled(Link)`
    display: none;

    @media screen and (min-width: 1000px) {
        display: inline-block;
        margin-left: 20px;
    }
`

export const Logo = styled.img`
    transition: all .2s ease;
    filter: drop-shadow(0 0 #fff2);

    @media screen and (min-width: 1000px) {
        height: 40px;
        :hover {
            transform: translateY(-2.5px);
            filter: drop-shadow(0 2.5px #fff5);
        }
    }
`

export const BtnBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: var(--color-white);
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 500;

    @media screen and (min-width: 1000px) {
        display: none;
    }
`

export const NavList = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0 2.5%;
    padding: 30px 0 40px;
    gap: 15px;
    position: relative;

    @media screen and (min-width: 1000px) {
        padding: 0;
        margin: unset;
        flex-direction: row;
        margin-right: 20px;
    }
`

export const NavLink = styled(Link)`
    color: var(--color-dark-blue);
    padding: 10px;
    font-weight: 500;
    font-size: 20px;
    display: block;
    border-radius: 5px;
    position: relative;
    z-index: 150;

    @media screen and (max-width: 1000px) {
        :active {
            background-color: var(--color-light-grey);
        }
    }

    @media screen and (min-width: 1000px) {
        color: var(--color-white);
        width: unset;
        padding: 10px 20px;
        border-radius: 10px;
        cursor: pointer;
    }
`

export const LinkTracker = styled.div`
    position: absolute;
    height: 100%;
    width: ${props => props.width}px;
    left: ${props => props.positionX}px;
    opacity: ${props => props.show ? 1 : 0};
    border-radius: 10px;
    background-color: #fff2;
    z-index: 100;
    transition: all .1s;
`